<template>
  <a-modal
    v-model:visible="showUploadModal"
    title="Upload Modal"
    centered
    :body-style="{ height: '80vh' }"
    width="40%"
    :mask-closable="false"
    @cancel="resetUploadStates"
  >
    <a-row :gutter="[0, 16]">
      <a-col span="24">
        <a-select
          ref="admin_model_version_upload_modal_select"
          v-model:value="selectedModelType"
          class="w-100"
          show-search
          :options="modelTypesOptions"
          placeholder="Select model type"
          :filter-option="true"
          option-filter-prop="label"
          @change="$refs.admin_model_version_upload_modal_select.blur()"
        />
      </a-col>

      <!-- <a-col span="24">
        <a-input 
          type="text"                              
          v-model:value="modelVersionInput"
          placeholder="Enter Model Version"
        />
      </a-col> -->

      <a-col span="24">
        <a-textarea 
          :rows="2" 
          v-model:value="modelDescriptionInput"
          placeholder="Enter Model Description" 
        />
      </a-col>

      <a-col v-if="selectedModelType" span="24">
        <a-typography-text>
          Required files for the selected model type are:
        </a-typography-text>
        <br />
        <a-tag
          v-for="file in modelTypesValidFiles[selectedModelType]"
          :key="file"
          :color="isFilesListContainedFile(file) ? 'success' : 'error'"
        >
          <template #icon>
            <check-circle-outlined v-if="isFilesListContainedFile(file)" />
            <exclamation-circle-outlined
              v-if="!isFilesListContainedFile(file)"
            />
          </template>
          {{ file }}
        </a-tag>
      </a-col>

      <a-col span="24" style="height:20vh !important">
        <a-upload-dragger
          :file-list="[]"
          name="file"
          multiple
          :before-upload="(f) => false"
          :class="{
            'disable-click': isUploadingModel,
          }"
          accept=".pt,.pth.tar,.json,.py"
          @change="handleAddFile"
        >
          <p class="ant-upload-drag-icon">
            <inbox-outlined />
          </p>
          <p class="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </a-upload-dragger>
      </a-col>
      <a-col span="24">
        <div class="list-container">
          <a-list :data-source="fileList" item-layout="horizontal" size="small">
            <template #renderItem="{ item }">
              <a-list-item>
                <a-list-item-meta
                  :description="getFileSize(item.size)"
                  class="d-flex align-items-center"
                >
                  <template #title>
                    <a-typography-text
                      :content="item.filePath"
                      :editable="{
                        onChange: (e) => handleFilePathChange(e, item.name),
                      }"
                    >
                      <template #editableIcon>
                        <HighlightOutlined />
                      </template>
                      <template #editableTooltip>
                        click to edit text
                      </template>
                    </a-typography-text>
                  </template>
                </a-list-item-meta>
                <template #actions>
                  <a-progress
                    v-if="!!uploadingDetails[item.name]"
                    type="circle"
                    :width="30"
                    :percent="uploadingDetails[item.name]"
                  />
                  <delete-outlined
                    class="text-danger clickable"
                    :class="{
                      disabled:
                        !!uploadingDetails[item.name] ||
                        filesUploaded.includes(item.name),
                    }"
                    @click="handleRemove(item)"
                  />
                </template>
              </a-list-item>
            </template>
          </a-list>
        </div>
      </a-col>
    </a-row>
    <template #footer>
      <a-button
        type="primary"
        :disabled="isDisabled"
        :loading="isUploadingModel"
        @click="handleUpload"
      >
        {{ isUploadingModel ? 'Uploading' : 'Upload' }}
      </a-button>
    </template>
  </a-modal>
  <a-table
    bordered
    :data-source="modelVersions"
    :columns="columns"
    :scroll="{
      y: '55vh',
      x: true,
    }"
    :pagination="{ position: ['bottomCenter'] }"
  >
    <template #title>
      <div class="d-flex">
        <a-typography-title :level="5" class="my-0">
          Task Models: &nbsp;
          <a-tag id="models-count" color="blue">
            {{ modelVersionsList?.length }}
          </a-tag>
        </a-typography-title>

        <a-button
          v-if="showUploadModelButton"
          class="ml-auto"
          @click="showUploadModal = true"
        >
          Upload Model
        </a-button>
      </div>
    </template>
    <template #emptyText>
      <div
        id="no-models-found"
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <laptop-outlined style="font-size: 40px" />
        <span class="mt-2">No Model Versions Found!</span>
      </div>
    </template>

    <template #bodyCell="{ column, text, record, index }">
      <template v-if="column.dataIndex === 'date'">
        {{ text ? dateHelper.formatDate(text) : '-' }}
      </template>

      <template v-if="column.dataIndex === 'description'">
        <div class="editable-cell">
          <a-textarea
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].description"
            class="description-edit-input"
            placeholder="Add description"
          />
          <div v-else>
            {{ text || ' ' }}
          </div>
        </div>
      </template>

      <!-- <template v-if="column.dataIndex === 'qc_check'">
        <div class="editable-cell">
          <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].qc_check"
            class="description-edit-input"
            placeholder="Add qc_check"
          />
          <div v-else>
            {{ text || '-' }}
          </div>
        </div>
      </template> -->

      <template v-if="column.dataIndex === 'deleted'">
        <a-switch :checked="record.is_deleted" @change="toggleDelete(record)" />
      </template>
      <template v-if="column.dataIndex === 'action'">
        <div v-if="editableData[record.id]">
          <a-button
            :loading="isUpdatingModelVersion"
            :disabled="!editableData[record.id].description"
            type="primary"
            class="mr-2"
            @click="saveRecord(record)"
          >
            <template #icon>
              <check-outlined />
            </template>
            Save
          </a-button>
          <a-button @click="cancelEdit(record.id)">
            Cancel
          </a-button>
        </div>

        <div v-else>
          <a-button
            type="primary"
            class="editable-cell-icon"
            :disabled="isEditDisabled"
            @click="editRecord(record.id)"
          >
            <template #icon>
              <edit-outlined />
            </template>
          </a-button>
          <!-- <a-button
            type="primary"
            class="editable-cell-icon"
            @click="handleShowLogs(record.id)"
          >
            Show Logs
          </a-button> -->
        </div>
      </template>
    </template>
  </a-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import dateHelper from '../../../shared/Helpers/dateHelper';
import S3Service from 'src/services/s3';
import {
  CheckOutlined,
  LaptopOutlined,
  EditOutlined,
  DeleteOutlined,
  HighlightOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { getAuthConfig } from 'src/services/config';
import { dateTimeFormat } from 'src/config/date-format-config';
import {
  validFiles,
  adminModelVersionTable as columns,
} from 'src/config/model-version-config';

export default {
  components: {
    CheckOutlined,
    EditOutlined,
    DeleteOutlined,
    LaptopOutlined,
    HighlightOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
  },
  inject: ['toast'],
  props: {
    orgName: {
      type: String,
      required: true,
      default: '',
    },
    taskId: {
      type: Number,
      required: true,
      default: 0,
    },
    taskName: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup() {
    return { columns, dateHelper };
  },

  data() {
    return {
      editableData: {},
      showUploadModal: false,
      selectedModelType: null,
      isUploadingModel: false,
      fileList: [],
      uploadingDetails: {},
      filesUploaded: [],
      modelTypesValidFiles: {},
      modelTypeIdToNameMap: {},
      modelVersionInput: null,
      modelDescriptionInput: null
    };
  },

  computed: {
    ...mapGetters([
      'modelTypes',
      'selectedTask',
      'modelVersionsList',
      'showUploadModelButton',
      'isUpdatingModelVersion',
      'taskAssociatedModelVersion',
    ]),
    modelVersions() {
      const modelTypesDict = this.modelTypes.reduce((res, el) => {
        res[el.id] = el.name;
        return res;
      }, {});

      return this.modelVersionsList.map((mv) => ({
        ...mv,
        model_type: modelTypesDict[mv.model_type],
      }));
    },

    isEditDisabled() {
      return Object.keys(this.editableData).length !== 0;
    },

    selectedModelVersion() {
      if (!this.taskAssociatedModelVersion) return;
      return this.modelVersionsList.find(
        (model) => model.id === this.taskAssociatedModelVersion
      );
    },

    modelTypesOptions() {
      return this.modelTypes.map((mt) => ({ value: mt.id, label: mt.name }));
    },

    isDisabled() {
      const filesToBeUploaded = this.fileList.map((f) => f.name);
      return (
        this.isUploadingModel ||
        this.fileList.length === 0 ||
        !this.selectedModelType ||
        !this.modelTypesValidFiles[this.selectedModelType].every((f) =>
          filesToBeUploaded.includes(f)
        )
      );
    },
  },

  watch: {
    modelTypes(value) {
      this.modelTypesValidFiles = value.reduce((res, el) => {
        res[el.id] = el.model_files.map((f) => f.file_name);
        return res;
      }, {});

      this.modelTypeIdToNameMap = value.reduce((res, mt) => {
        res[mt.id] = mt.name;
        return res;
      }, {});
    },
  },

  mounted() {
    if (!this.selectedTask && this.modelVersionsList?.length) {
      this.clearModelVersionsList();
    }
  },

  methods: {
    ...mapActions([
      'toggleDelete',
      'updateModelVersion',
      'createModelVersion',
      'clearModelVersionsList',
    ]),
    isFilesListContainedFile(file) {
      return this.fileList.map((f) => f.name).includes(file);
    },
    handleFilePathChange(filePath, fileName) {
      this.fileList = this.fileList.map((f) => {
        if (f.name == fileName) {
          f['filePath'] = filePath;
        }
        return f;
      });
    },

    validateRegex() {
      const regex = /^\d\.\d$/;
      if (!regex.test(this.modelVersionInput)) {
        this.toast.error('Invalid Model Version Format');
        return false
      }
      return true
    },

    editRecord(recordId) {
      const editRecord = this.modelVersionsList.filter(
        (item) => item.id === recordId
      )[0];
      this.editableData = {
        ...this.editableData,
        [recordId]: { ...editRecord },
      };
    },

    async saveRecord(record) {
      const editedRecord = this.editableData[record.id];

      if(editedRecord.description.length < 50) {
        this.toast.error('The description must be at least 50 characters long.');
        return
      }
      
      const data = {
        modelVersionId: editedRecord.id,
        payload: {
          description: editedRecord.description,
          qc_check: editedRecord.qc_check,
        },
      };

      const res = await this.updateModelVersion(data);
      if (!res) {
        this.toast.error('Error occurred in updating model verison!');
      }
      delete this.editableData[record.id];
    },

    cancelEdit(recordId) {
      delete this.editableData[recordId];
    },

    // handleShowLogs(recordId) {
    //   const selectedRecord = this.modelVersionsList.filter(
    //     (item) => item.id === recordId
    //   )[0];
    //   if (selectedRecord?.train_logs) {
    //     window.open(selectedRecord.train_logs, '_blank');
    //     return;
    //   }
    //   const urlLink = 'https://google.com';
    //   window.open(urlLink, '_blank');
    // },

    isFileExist(file) {
      return this.fileList
        .map((e) => e.name + e.size)
        .includes(file.name + file.size);
    },

    isValidFile(file) {
      return validFiles.some((ex) => file.name.includes(ex));
    },

    handleAddFile({ file }) {
      if (this.isFileExist(file)) {
        this.toast.info(`${file.name} already exists!`, { timeout: 2000 });
        return;
      }
      if (this.isValidFile(file)) {
        file['filePath'] = file.name;
        this.fileList = [...this.fileList, file];
      }
    },

    getFileSize(sizeInBytes) {
      if (sizeInBytes < 102400) return (sizeInBytes / 1024).toFixed(2) + ' KB';
      return (sizeInBytes / 1024 / 1024).toFixed(2) + ' MB';
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((f) => f.uid !== file.uid);
    },

    async handleUpload() {
      // if(!this.validateRegex()) return

      // if(this.modelVersionsList.includes(this.modelVersionInput))
      // {
      //   this.toast.error('Model Version already exists.');
      //   return
      // }

      if(this.modelDescriptionInput.length < 50) {
        this.toast.error('The description must be at least 50 characters long.');
        return
      }
      this.isUploadingModel = true;
      const modelCreationDate = new Date().toISOString();
      const modelS3Key = this.getModelS3Key(modelCreationDate);
      for (const file of this.fileList) {
        const payload = this.getPayload(file, modelS3Key);
        const config = this.getUploadConfig(file.name);

        const [error] = await S3Service.uploadFile(payload, false, config);
        console.log('error', { [file.name]: error });
        this.filesUploaded = [...this.filesUploaded, file.name];
      }

      const payloadForModelVersion = this.getPayloadForModelVersion(
        modelS3Key,
        modelCreationDate
      );

      await this.createModelVersion(payloadForModelVersion);

      this.isUploadingModel = false;
      this.uploadingDetails = {};
      this.showUploadModal = false;
    },

    getUploadConfig(fileName) {
      return {
        ...getAuthConfig(),
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent <= 100) {
            this.uploadingDetails = {
              ...this.uploadingDetails,
              [fileName]: percent,
            };
          }
        },
      };
    },

    getPayloadForModelVersion(modelS3Key, modelCreationDate) {
      return {
        Task: this.taskId,
        date: modelCreationDate,
        model_type: this.selectedModelType,
        description: this.modelDescriptionInput,
        model_version: this.modelVersionInput,
        model_s3_key: modelS3Key,
      };
    },

    getModelS3Key(modelCreationDate) {
      const formattedDate = dateHelper.formatDate(
        modelCreationDate,
        dateTimeFormat
      );
      const modelType = this.modelTypeIdToNameMap[this.selectedModelType];
      const s3Key = `${this.taskName}/${modelType}/${formattedDate}/`;
      // const s3Key = `${this.taskName}/history/${modelType}/${formattedDate}/`;
      return s3Key;
    },

    getPayload(file, modelS3Key) {
      const bucket = 'retrocausal-model-versions';
      const filePath = `${this.orgName}-training/${modelS3Key}${file.filePath}`;
      const payload = new FormData();
      payload.append('file', file);
      payload.append('bucket', bucket);
      payload.append('file_path', filePath);
      return payload;
    },

    resetUploadStates() {
      this.fileList = [];
      this.selectedModelType = null;
      this.uploadingDetails = {};
    },
  },
};
</script>
<style>
.editable-cell {
  max-width: 350px;
}

.editable-cell-text-wrapper,
.editable-cell-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.description-edit-input {
  width: 90%;
}

.editable-cell-icon {
  margin-right: 10px;
  margin-bottom: 5px;
}

.list-container {
  max-height: 25vh;
  overflow-y: auto;
}

.clickable {
  cursor: pointer;
}

.disabled {
  pointer-events: none !important;
  color: gray !important;
}
</style>
